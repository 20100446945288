import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { Car, DollarSign, AlertTriangle, Info } from 'lucide-react';

export default function VehicleDebtChecker() {
  const { register, handleSubmit, formState: { errors } } = useForm();
  const [debtInfo, setDebtInfo] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const onSubmit = async (data) => {
    setLoading(true);
    setError(null);
    setDebtInfo(null);
    try {
      const response = await fetch('/api/check-debts', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      });
      const result = await response.json();
      if (!response.ok) {
        throw result;
      }
      setDebtInfo(result);
    } catch (err) {
      console.error('Erro na consulta:', err);
      setError(err.message || 'Erro ao consultar débitos');
    } finally {
      setLoading(false);
    }
  };

  const hasNoDebts = debtInfo && 
    debtInfo.ipva === '0,00' && 
    debtInfo.totalMultas === '' && 
    debtInfo.debitos === '0,00' && 
    debtInfo.dividaAtiva === '0,00';

  return (
    <div className="max-w-md mx-auto">
      <div className="bg-white rounded-lg shadow-md p-6">
        <h2 className="text-2xl font-bold mb-4 flex items-center justify-center">
          <Car className="mr-2" /> Consulta de Débitos
        </h2>
        <div className="mb-4 bg-blue-100 border-l-4 border-blue-500 text-blue-700 p-4">
          <p className="font-bold">Aviso</p>
          <p>Esta consulta é exclusiva para veículos do estado de São Paulo.</p>
        </div>
        <form onSubmit={handleSubmit(onSubmit)} className="space-y-4">
          <div>
            <label htmlFor="renavam" className="block mb-1">Renavam</label>
            <input
              {...register('renavam', { required: 'Renavam é obrigatório' })}
              id="renavam"
              type="text"
              className="w-full p-2 border rounded"
              placeholder="Digite o Renavam"
            />
            {errors.renavam && <p className="text-red-500 text-sm">{errors.renavam.message}</p>}
          </div>
          <div>
            <label htmlFor="placa" className="block mb-1">Placa</label>
            <input
              {...register('placa', { required: 'Placa é obrigatória' })}
              id="placa"
              type="text"
              className="w-full p-2 border rounded"
              placeholder="Digite a Placa"
            />
            {errors.placa && <p className="text-red-500 text-sm">{errors.placa.message}</p>}
          </div>
          <button
            type="submit"
            className="w-full bg-blue-500 text-white p-2 rounded hover:bg-blue-600 flex items-center justify-center"
            disabled={loading}
          >
            {loading ? (
              <span className="flex items-center">
                <svg className="animate-spin h-5 w-5 mr-3" viewBox="0 0 24 24">
                  <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                  <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                </svg>
                Consultando...
              </span>
            ) : (
              <>
                <DollarSign className="mr-2" />
                Consultar Débitos
              </>
            )}
          </button>
        </form>

        {error && (
          <div className="mt-4 bg-red-100 border-l-4 border-red-500 text-red-700 p-4" role="alert">
            <p className="font-bold">Erro:</p>
            <p>{error}</p>
          </div>
        )}

        {debtInfo && (
          <div className="mt-6 bg-gray-100 rounded-lg p-4">
            <h3 className="text-xl font-semibold text-gray-800 mb-3">Resultado da Consulta:</h3>
            
            {hasNoDebts ? (
              <div className="bg-green-100 border-l-4 border-green-500 text-green-700 p-4 mb-4" role="alert">
                <Info className="inline-block mr-2" />
                <span className="font-bold">Não há débitos para este veículo.</span>
              </div>
            ) : (
              <div className="space-y-2">
                <p className="flex items-center justify-between">
                  <span className="font-medium">IPVA:</span>
                  <span className="text-blue-600 font-bold">{debtInfo.ipva}</span>
                </p>
                <p className="flex items-center justify-between">
                  <span className="font-medium">Total de Multas:</span>
                  <span className="text-blue-600 font-bold">{debtInfo.totalMultas}</span>
                </p>
                <p className="flex items-center justify-between">
                  <span className="font-medium">Total de Débitos:</span>
                  <span className="text-blue-600 font-bold">{debtInfo.debitos}</span>
                </p>
                <p className="flex items-center justify-between">
                  <span className="font-medium">Débito inscrito em dívida ativa:</span>
                  <span className="text-blue-600 font-bold">{debtInfo.dividaAtiva}</span>
                </p>
              </div>
            )}
            
            <p className="flex items-center justify-between mt-3">
              <span className="font-medium">Comunicação de venda:</span>
              <span className="text-blue-600 font-bold">{debtInfo.comunicacaoVenda ? 'Sim' : 'Não'}</span>
            </p>

            {debtInfo.comunicacaoVenda && (
              <div className="mt-4 bg-yellow-100 border-l-4 border-yellow-500 text-yellow-700 p-4" role="alert">
                <AlertTriangle className="inline-block mr-2" />
                <p className="font-bold">Atenção:</p>
                <p>Veículo com comunicação de venda. Não é possível licenciar. Para deixá-lo em dia, é necessário concluir a transferência.</p>
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
}
