import React from 'react';

const Contact = () => {
  return (
    <div className="container mx-auto mt-8">
      <h1 className="text-3xl font-bold mb-4">Entre em Contato</h1>
      <p>Para dúvidas ou suporte, entre em contato conosco:</p>
      <ul className="list-disc list-inside mt-4">
        <li>Email: contato@consultasp.com</li>
        <li>Telefone: (11) 1234-5678</li>
        <li>Endereço: Av. Paulista, 1000 - São Paulo, SP</li>
      </ul>
    </div>
  );
};

export default Contact;
