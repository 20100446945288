import React from 'react';

const AboutUs = () => {
  return (
    <div className="container mx-auto mt-8">
      <h1 className="text-3xl font-bold mb-4">Quem Somos</h1>
      <p className="mb-4">
        A ConsultaSP é uma empresa dedicada a fornecer serviços de consulta de débitos 
        de veículos para o estado de São Paulo de forma gratuita e acessível.
      </p>
      <p className="mb-4">
        Nossa missão é simplificar o processo de verificação de débitos, ajudando os 
        proprietários de veículos a manterem suas documentações em dia de forma fácil e rápida.
      </p>
      <p>
        Fundada em 2023, nossa equipe é composta por profissionais experientes em tecnologia 
        e no setor automotivo, comprometidos em oferecer um serviço confiável e de qualidade.
      </p>
    </div>
  );
};

export default AboutUs;
