import React from 'react';
import { Link } from 'react-router-dom';
import logo from '../img/logo.png';

const Header = () => {
  return (
    <header className="bg-gray-200 text-gray-800">
      <div className="container mx-auto px-4 py-4">
        <div className="flex flex-col md:flex-row justify-between items-center">
          <div className="flex items-center justify-center w-full md:w-auto mb-4 md:mb-0">
            <img 
              src={logo} 
              alt="ConsultaSP Logo" 
              className="h-40 md:h-48 w-auto object-contain" 
            />
          </div>
          <nav>
            <ul className="flex space-x-4">
              <li><Link to="/" className="hover:text-gray-600">Home</Link></li>
              <li><Link to="/contato" className="hover:text-gray-600">Contato</Link></li>
              <li><Link to="/quem-somos" className="hover:text-gray-600">Quem Somos</Link></li>
            </ul>
          </nav>
        </div>
      </div>
    </header>
  );
};

export default Header;
